<template>
  <div>
    <div class="flex flex-wrap gap-5 mb-5 mt-5">
      <div class="flex flex-col bg-blue-950 text-white p-6 w-7/12 rounded">
        <div class="flex justify-between text-blue-200 text-11 mb-2">
          <span>Cash Balance</span>
          <span :class="isLocked ? 'text-orange-400' : 'text-green-400'">
            Cash {{ isLocked ? 'Locked' : 'Active' }}
          </span>
        </div>
        <div class="text-18 font-bold mr-2 mb-2">
          {{ wallet.balance | formatAmount }}
        </div>
        <div class="flex flex-wrap gap-4 items-center justify-between mr-2 mb-2">
          <div class="text-12 text-blue-200">
            Book balance: {{ wallet.ledger_balance | formatAmount }}
          </div>
          <div v-if="virtualAccount">
            <div class="text-10">Virtual Account</div>
            <div class="text-12">
              <span class="uppercase">{{ virtualAccount.bank_name }}: </span>
              <span>{{ virtualAccount.account_number }}</span>
            </div>
          </div>
        </div>
        <div class="text-10 text-green-400">
          Transaction limit:
          <span v-if="wallet.limit">
            {{ wallet.limit | formatAmount }}
          </span>
          <span v-else>
            Not set
          </span>
           
        </div>
      </div>
      <div class="flex flex-col">
        <div class="mb-5">
          <button
            v-if="canDo('credpal:can_debit_user_wallet')"
            type="button"
            class="btn btn-blue"
            @click="openManualDebitModal"
          >
            Debit Cash Wallet
          </button>
        </div>
        <div>
          <button
            v-if="canDo('credpal:can_update_user_wallet_limit')"
            type="button"
            class="btn btn-blue"
            @click="openWalletLimitModal"
          >
            Update Wallet Limit
          </button>
        </div>
      </div>
      
    </div>
    <debit-cash-wallet :wallet="wallet" :staff="staff" ref="manualDebit" />
    <update-cash-wallet-limit :wallet="wallet" :staff="staff" ref="updateWalletLimit" />
  </div>
</template>

<script>
export default {
  props: {
    staff: {
      type: Object,
      default: () => ({}),
    },
    wallet: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isLocked() {
      return this.wallet?.is_locked;
    },
    virtualAccount() {
      return this.wallet?.virtual_account;
    },
  },
  methods: {
    openManualDebitModal() {
      this.$refs.manualDebit.open();
    },
    openWalletLimitModal() {
      this.$refs.updateWalletLimit.open();
    },
  },
};
</script>
